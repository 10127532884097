import React from 'react';
import PropTypes from 'prop-types';

const TikTokIcon = ({ height = 18, width = 18, className = '' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height={height}
            width={width}
            className={className}
        >
            <path
                fill="#69c9d0"
                d="M22.82,6.91c-.17-1.45-.82-2.74-2.24-3.66a4.45,4.45,0,0,0-2.89-.8c-1.61,0-3.22,0-4.84,0a2.48,2.48,0,0,0-2.32,1.38,3.29,3.29,0,0,0,.28,3.26c.57,1.08,1.64,1.52,2.89,1.58a4.45,4.45,0,0,0,3.23-1.23,4.77,4.77,0,0,0,1-3.23Z"
            />
            <path
                fill="#ee1d52"
                d="M9.39,21.3A7.15,7.15,0,0,1,6.24,20a6.67,6.67,0,0,1-2.85-6.1V6.23c0-1.22.23-2.38,1.37-3.32a4.8,4.8,0,0,1,3.79-1.44c1.42,0,2.84,0,4.26,0a1.76,1.76,0,0,1,1.82,1.77V14.91a6.69,6.69,0,0,1-1.82,4.87,7.45,7.45,0,0,1-5.34,2.52Z"
            />
        </svg>
    );
};

TikTokIcon.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string
};

export default TikTokIcon;
